import { APICore } from "../helpers/api/apiCore";
import { hasPermissionToFolder } from "../helpers";

const api = new APICore();

export const getModules = () => {
  return api.get("/settings/get-modules").then((res) => res.data);
};

export const getModuleState = (name) => {
  return api.get(`/settings/get-module/${name}`).then((res) => res.data);
};

export const toggleModule = (name) => {
  return api.update(`/settings/switch-module/${name}`).then((res) => res.data);
};

export const getRoles = () => {
  return api.get(`/roles/get`).then((res) => res.data);
};

export const getRolesForDropdown = () => {
  return api.get(`/roles/get-for-dropdown`).then((res) => res.data);
};

export const getRolePermissions = (id) => {
  return api.get(`/roles/get-permissions/${id}`).then((res) => res.data);
};

export const updateRolePermissions = (data) => {
  return api.update(`/roles/update-permissions`, data).then((res) => res.data);
};

export const addNewRole = (data) => {
  return api.create(`/roles/add`, data).then((res) => res.data);
};

export const deleteRole = (id) => {
  return api.delete(`/roles/delete/${id}`).then((res) => res.data);
};

export const getAllFileCategories = () => {
  return api.get(`/settings/get-file-categories`).then((res) => res.data);
};

export const getFileCategories = async () => {
  const availableFoldersList = [];
  const allFileCategories = await getAllFileCategories();

  allFileCategories.forEach((category) => {
    if (hasPermissionToFolder(category.categoryName, allFileCategories)) {
      availableFoldersList.push({
        value: category.categoryName,
        label: category.categoryName,
      });
    }
  });

  return availableFoldersList;
};

export const getSettingsListsForSelect = () => {
  return api.get("/settings/get").then((res) => {
    let results = {
      investors: [],
      offices: [],
    };

    const settings = res.data.settings[0];

    settings.investors.sort().forEach((investor) => {
      results.investors.push({
        value: investor,
        label: investor,
      });
    });

    settings.offices.sort().forEach((office) => {
      results.offices.push({
        value: office,
        label: office,
      });
    });

    return results;
  });
};
