const general = {
  SHOW_DASHBOARD: { key: "show_dashboard", value: "Wyświetlanie ekranu głównego" },
};

const projects = {
  SHOW_PROJECTS: { key: "show_projects", value: "Wyświetlanie listy projektów" },
  SHOW_PROJECT: { key: "show_project", value: "Wyświetlanie szczegółów projektu" },
  ADD_PROJECT: { key: "add_project", value: "Dodawanie nowego projektu" },
  EDIT_PROJECT: { key: "edit_project", value: "Edycja projektu" },
  ADD_ATTACHMENTS: { key: "add_project_attachment", value: "Dodawanie załącznika do projektu" },
  DELETE_ATTACHMENTS: { key: "delete_project_attachment", value: "Usuwanie załącznika z projektu" },
  EDIT_OBSERVERS: { key: "edit_project_observers", value: "Edycja obserwatorów projektu" },
  SHOW_PROJECT_FINANCIAL_INFO: { key: "show_project_financial_info", value: "Wyświetlanie informacji finansowych projektu" },
  DELETE_PROJECT: { key: "delete_project", value: "Usuwanie projektu" },
  MANAGE_INVOICES_IN_PROJECT: { key: "manage_invoices_in_project", value: "Wyświetlanie oraz dodawanie faktur kosztowych w projekcie" },
  SHOW_MONTH_SWITCHER_IN_PROJECTS_LIST: { key: "show_month_switcher_in_projects_list", value: "Wyświetlanie przycisków zmiany miesiąca w liście projektów" },
};

const vacations = {
  SHOW_MY_VACATIONS: { key: "show_my_vacations", value: "Wyświetlanie strony moje urlopy" },
  SHOW_VACATION_DETAILS: { key: "show_vacation_details", value: "Wyświetlanie szczegółów urlopu" },
  DELETE_VACATION: { key: "delete_vacation", value: "Usuwanie urlopu" },
  SHOW_VACATION_EMPLOYEES_LIST_ALL: { key: "show_vacation_employees_list_all", value: "Wyświetlanie listy wszystkich pracowników i ich urlopów" },
  SHOW_VACATION_EMPLOYEES_LIST_ONLY_OFFICE: { key: "show_vacation_employees_list_only_office", value: "Wyświetlanie listy pracowników tylko z biura użytkownika i ich urlopów" },
  SHOW_VACATION_EMPLOYEE_SUMMARY: { key: "show_vacation_employee_summary", value: "Wyświetlanie podsumowania urlopów pracownika" },
  EDIT_VACATION_EMPLOYEE_DAYS: { key: "edit_vacation_employee_days", value: "Edycja dni urlopowych pracownika" },
  ADD_EMPLOYEE_VACATION_MANUALLY: { key: "add_employee_vacation_manually", value: "Manualne dodawanie urlopu pracownikowi" },
  SHOW_VACATION_REQUESTS_LIST: { key: "show_vacation_requests_list", value: "Wyświetlanie listy wniosków urlopowych" },
  SHOW_VACATION_REQUEST_DETAILS: { key: "show_vacation_request_details", value: "Wyświetlanie szczegółów wniosku urlopowego" },
  APPROVE_VACATION_REQUEST: { key: "approve_vacation_request", value: "Akceptacja wniosku urlopowego" },
  DELETE_VACATION_REQUEST: { key: "delete_vacation_request", value: "Usuwanie wniosku urlopowego" },
  SHOW_VACATION_CALENDAR: { key: "show_vacation_calendar", value: "Wyświetlanie kalendarza urlopów" },
};

const administration = {
  SHOW_ADMINISTRATION_PANEL: { key: "show_administration_panel", value: "Wyświetlanie panelu administracyjnego" },
  ADD_USER: { key: "add_user", value: "Dodawanie użytkownika" },
  EDIT_USER: { key: "edit_user", value: "Edycja użytkownika" },
  DELETE_USER: { key: "delete_user", value: "Usuwanie użytkownika" },
  GET_ROLES: { key: "get_roles", value: "Pobieranie listy ról" },
  ADD_PERMISSION_TO_ROLE: { key: "add_permission_to_role", value: "Dodawanie uprawnień do roli" },
  ADD_PERMISSION_TO_USER: { key: "add_permission_to_user", value: "Dodawanie uprawnień do użytkownika" },
  ADD_NEW_ROLE: { key: "add_new_role", value: "Dodawanie nowej roli" },
  DELETE_ROLE: { key: "delete_role", value: "Usuwanie roli" },
  GET_USERS_LIST_FOR_DROPDOWN: { key: "get_users_list_for_dropdown", value: "Pobieranie listy użytkowników do formularza" },
};

const statuses = {
  SHOW_STATUSES: { key: "show_statuses", value: "Wyświetlanie listy statusów" },
  ADD_STATUS: { key: "add_status", value: "Dodawanie statusu" },
  EDIT_STATUS: { key: "edit_status", value: "Edycja statusu" },
  DELETE_STATUS: { key: "delete_status", value: "Usuwanie statusu" },
};

const settings = {
  SHOW_SETTINGS: { key: "show_settings", value: "Wyświetlanie ustawień" },
  EDIT_SETTINGS: { key: "edit_settings", value: "Edycja ustawień" },
  SHOW_RESTRICTED_ATTACHMENTS_FOLDERS: { key: "show_restricted_attachments_folders", value: "Wyświetlanie załączników z ograniczonym dostępem" },
};

const cars = {
  SHOW_FLEET: { key: "show_fleet", value: "Wyświetlanie listy pojazdów" },
  SHOW_CARS_FROM_OFFICE: { key: "show_cars_from_office", value: "Wyświetlanie listy pojazdów z biura użytkownika" },
  SHOW_ALL_CARS: { key: "show_all_cars", value: "Wyświetlanie listy wszystkich pojazdów" },
  SHOW_WATCHING_CARS: { key: "show_watching_cars", value: "Wyświetlanie wyłącznie obserwowanych pojazdów" },
  SHOW_CAR: { key: "show_car", value: "Wyświetlanie szczegółów pojazdu" },
  ADD_CAR: { key: "add_car", value: "Dodawanie pojazdu" },
  EDIT_CAR: { key: "edit_car", value: "Edycja pojazdu" },
  DELETE_CAR: { key: "delete_car", value: "Usuwanie pojazdu" },
  ADD_ATTACHMENTS: { key: "add_attachments_to_car", value: "Dodawanie załączników do pojazdu" },
  DELETE_ATTACHMENTS: { key: "delete_car_attachments", value: "Usuwanie załączników dotyczących pojazdu" },
  EDIT_OBSERVERS: { key: "edit_car_observers", value: "Edycja obserwatorów pojazdu" },
};

const computers = {
  SHOW_COMPUTERS: { key: "show_computers", value: "Wyświetlanie listy komputerów" },
  SHOW_COMPUTER: { key: "show_computer", value: "Wyświetlanie szczegółów komputera" },
  ADD_COMPUTER: { key: "add_computer", value: "Dodawanie komputera" },
  EDIT_COMPUTER: { key: "edit_computer", value: "Edycja komputera" },
  DELETE_COMPUTER: { key: "delete_computer", value: "Usuwanie komputera" },
  ADD_ATTACHMENTS: { key: "add_attachments_to_computer", value: "Dodawanie załączników do komputera" },
  DELETE_ATTACHMENTS: { key: "delete_computer_attachments", value: "Usuwanie załączników dotyczących komputera" },
  EDIT_OBSERVERS: { key: "edit_computer_observers", value: "Edycja obserwatorów komputera" },
};

module.exports = {
  general,
  projects,
  vacations,
  administration,
  statuses,
  settings,
  cars,
  computers,
};
