import React from "react";
import { Redirect } from "react-router-dom";
import { Route } from "react-router-dom";

import PrivateRoute from "./PrivateRoute";

import permissions from "../constants/permissions";

// lazy load all the views

// auth
const Login = React.lazy(() => import("../pages/account/Login"));
const Logout = React.lazy(() => import("../pages/account/Logout"));
const Register = React.lazy(() => import("../pages/account/Register"));
// const Confirm = React.lazy(() => import('../pages/account/Confirm'));
// const ForgetPassword = React.lazy(() => import('../pages/account/ForgetPassword'));
// const LockScreen = React.lazy(() => import('../pages/account/LockScreen'));

// const Login2 = React.lazy(() => import('../pages/account2/Login2'));
// const Logout2 = React.lazy(() => import('../pages/account2/Logout2'));
// const Register2 = React.lazy(() => import('../pages/account2/Register2'));
// const Confirm2 = React.lazy(() => import('../pages/account2/Confirm2'));
// const ForgetPassword2 = React.lazy(() => import('../pages/account2/ForgetPassword2'));
// const LockScreen2 = React.lazy(() => import('../pages/account2/LockScreen2'));

// dashboard
// const AnalyticsDashboard = React.lazy(() => import('../pages/dashboard/Analytics'));
// const EcommerceDashboard = React.lazy(() => import('../pages/dashboard/Ecommerce'));
// const CRMDashboard = React.lazy(() => import('../pages/dashboard/CRM'));
// const ProjectDashboard = React.lazy(() => import("../pages/dashboard/Project"));

/************ MINER ************/

// miner custom Piomartel
const Dashboard = React.lazy(() => import("../pages/miner_custom/Dashboard/Dashboard"));
const Projekty = React.lazy(() => import("../pages/miner_custom/Projects/Projekty"));
const Fleet = React.lazy(() => import("../pages/miner_custom/Cars/Fleet"));
const Computers = React.lazy(() => import("../pages/miner_custom/Computers/Computers"));
const Vacation = React.lazy(() => import("../pages/miner_custom/Vacation/Vacation"));
const VacationCalendar = React.lazy(() => import("../pages/miner_custom/Vacation/VacationCalendar"));
const VacationEmployees = React.lazy(() => import("../pages/miner_custom/Vacation/VacationEmployees"));
const VacationRequests = React.lazy(() => import("../pages/miner_custom/Vacation/VacationRequests"));
const VacationRequest = React.lazy(() => import("../pages/miner_custom/Vacation/VacationRequest"));
const ProjectDetails = React.lazy(() => import("../pages/miner_custom/Projects/ProjectDetails"));
const CarDetails = React.lazy(() => import("../pages/miner_custom/Cars/CarDetails"));
const ComputerDetails = React.lazy(() => import("../pages/miner_custom/Computers/ComputerDetails"));
const AddProject = React.lazy(() => import("../pages/miner_custom/Projects/AddProject"));
const AddCar = React.lazy(() => import("../pages/miner_custom/Cars/AddCar"));
const AddComputer = React.lazy(() => import("../pages/miner_custom/Computers/AddComputer"));
const Employees = React.lazy(() => import("../pages/miner_custom/Employees"));
const Statuses = React.lazy(() => import("../pages/miner_custom/Statuses"));
const Settings = React.lazy(() => import("../pages/miner_custom/Settings/Settings"));

// miner custom Polana
// const ProjektyPolana = React.lazy(() => import('../pages/miner_custom/Projekty'));

// miner custom Novo
// const ProjektyNovo = React.lazy(() => import('../pages/miner_custom/Projekty'));

/************ MINER ************/

// apps
// const CalendarApp = React.lazy(() => import('../pages/apps/Calendar/'));
// const Projects = React.lazy(() => import('../pages/apps/Projects/'));
// const ProjectDetails = React.lazy(() => import('../pages/apps/Projects/Detail/'));
// const ProjectGannt = React.lazy(() => import('../pages/apps/Projects/Gantt/'));
// const ProjectForm = React.lazy(() => import('../pages/apps/Projects/ProjectForm'));
// - chat
// const ChatApp = React.lazy(() => import('../pages/apps/Chat/'));
// - ecommece pages
// const EcommerceProducts = React.lazy(() => import('../pages/apps/Ecommerce/Products'));
// const ProductDetails = React.lazy(() => import('../pages/apps/Ecommerce/ProductDetails'));
// const Orders = React.lazy(() => import('../pages/apps/Ecommerce/Orders'));
// const OrderDetails = React.lazy(() => import('../pages/apps/Ecommerce/OrderDetails'));
// const Customers = React.lazy(() => import('../pages/apps/Ecommerce/Customers'));
// const Cart = React.lazy(() => import('../pages/apps/Ecommerce/Cart'));
// const Checkout = React.lazy(() => import('../pages/apps/Ecommerce/Checkout/'));
// const Sellers = React.lazy(() => import('../pages/apps/Ecommerce/Sellers'));
// - email
// const Inbox = React.lazy(() => import('../pages/apps/Email/Inbox'));
// const EmailDetail = React.lazy(() => import('../pages/apps/Email/Detail'));
// - social
// const SocialFeed = React.lazy(() => import('../pages/apps/SocialFeed/'));
// - tasks
// const TaskList = React.lazy(() => import('../pages/apps/Tasks/List/'));
// const TaskDetails = React.lazy(() => import('../pages/apps/Tasks/Details'));
// const Kanban = React.lazy(() => import('../pages/apps/Tasks/Board/'));
// - file
// const FileManager = React.lazy(() => import('../pages/apps/FileManager'));

// pages
// const Profile = React.lazy(() => import('../pages/profile'));
// const Profile2 = React.lazy(() => import('../pages/profile2'));
const ErrorPageNotFound = React.lazy(() => import("../pages/error/PageNotFound"));
// const ErrorPageNotFoundAlt = React.lazy(() => import('../pages/error/PageNotFoundAlt'));
const ServerError = React.lazy(() => import("../pages/error/ServerError"));
// - other
// const Invoice = React.lazy(() => import('../pages/other/Invoice'));
// const FAQ = React.lazy(() => import('../pages/other/FAQ'));
// const Pricing = React.lazy(() => import('../pages/other/Pricing'));
const Maintenance = React.lazy(() => import("../pages/other/Maintenance"));
// const Starter = React.lazy(() => import('../pages/other/Starter'));
// const PreLoader = React.lazy(() => import('../pages/other/PreLoader/'));
// const Timeline = React.lazy(() => import('../pages/other/Timeline'));

// const Landing = React.lazy(() => import('../pages/landing/'));

// uikit
// const Accordions = React.lazy(() => import('../pages/uikit/Accordions'));
// const Alerts = React.lazy(() => import('../pages/uikit/Alerts'));
// const Avatars = React.lazy(() => import('../pages/uikit/Avatars'));
// const Badges = React.lazy(() => import('../pages/uikit/Badges'));
// const Breadcrumbs = React.lazy(() => import('../pages/uikit/Breadcrumb'));
// const Buttons = React.lazy(() => import('../pages/uikit/Buttons'));
// const Cards = React.lazy(() => import('../pages/uikit/Cards'));
// const Carousels = React.lazy(() => import('../pages/uikit/Carousel'));
// const Dropdowns = React.lazy(() => import('../pages/uikit/Dropdowns'));
// const EmbedVideo = React.lazy(() => import('../pages/uikit/EmbedVideo'));
// const Grid = React.lazy(() => import('../pages/uikit/Grid'));
// const ListGroups = React.lazy(() => import('../pages/uikit/ListGroups'));
// const Modals = React.lazy(() => import('../pages/uikit/Modals'));
// const Notifications = React.lazy(() => import('../pages/uikit/Notifications'));
// const Offcanvases = React.lazy(() => import('../pages/uikit/Offcanvas'));
// const Paginations = React.lazy(() => import('../pages/uikit/Paginations'));
// const Popovers = React.lazy(() => import('../pages/uikit/Popovers'));
// const Progress = React.lazy(() => import('../pages/uikit/Progress'));
// const Ribbons = React.lazy(() => import('../pages/uikit/Ribbons'));
// const Spinners = React.lazy(() => import('../pages/uikit/Spinners'));
// const Tabs = React.lazy(() => import('../pages/uikit/Tabs'));
// const Tooltips = React.lazy(() => import('../pages/uikit/Tooltips'));
// const Typography = React.lazy(() => import('../pages/uikit/Typography'));
// const DragDrop = React.lazy(() => import('../pages/uikit/DragDrop'));
// const RangeSliders = React.lazy(() => import('../pages/uikit/RangeSliders'));
// const Ratings = React.lazy(() => import('../pages/uikit/Ratings'));

// icons
// const Dripicons = React.lazy(() => import('../pages/uikit/Dripicons'));
// const MDIIcons = React.lazy(() => import('../pages/uikit/MDIIcons'));
// const Unicons = React.lazy(() => import('../pages/uikit/Unicons'));

// forms
// const BasicForms = React.lazy(() => import('../pages/forms/Basic'));
// const FormAdvanced = React.lazy(() => import('../pages/forms/Advanced'));
// const FormValidation = React.lazy(() => import('../pages/forms/Validation'));
// const FormWizard = React.lazy(() => import('../pages/forms/Wizard'));
// const FileUpload = React.lazy(() => import('../pages/forms/FileUpload'));
// const Editors = React.lazy(() => import('../pages/forms/Editors'));

// charts
// const ApexChart = React.lazy(() => import('../pages/charts/Apex'));
// const BriteChart = React.lazy(() => import('../pages/charts/Brite'));
// const ChartJs = React.lazy(() => import('../pages/charts/ChartJs'));

// tables
// const BasicTables = React.lazy(() => import('../pages/tables/Basic'));
// const AdvancedTables = React.lazy(() => import('../pages/tables/Advanced'));

// widgets
// const Widgets = React.lazy(() => import('../pages/uikit/Widgets'));

// maps
// const GoogleMaps = React.lazy(() => import('../pages/maps/GoogleMaps'));
// const VectorMaps = React.lazy(() => import('../pages/maps/VectorMaps'));

// root routes
const rootRoute = {
  path: "/",
  exact: true,
  component: () => <Redirect to="/dashboard" />,
  route: PrivateRoute,
};

// dashboards
const dashboardRoutes = {
  path: "/dashboard",
  name: "Strona główna",
  icon: "uil-home-alt",
  component: Dashboard,
  route: PrivateRoute,
  // header: 'Navigation',
  // children: [
  //     {
  //         path: '/dashboard/analytics',
  //         name: 'Analytics',
  //         component: AnalyticsDashboard,
  //         route: PrivateRoute,
  //     },
  //     {
  //         path: '/dashboard/crm',
  //         name: 'CRM',
  //         component: CRMDashboard,
  //         route: PrivateRoute,
  //     },
  //     {
  //         path: '/dashboard/ecommerce',
  //         name: 'Ecommerce',
  //         badge: {
  //             variant: 'success',
  //             text: '3',
  //         },
  //         component: EcommerceDashboard,
  //         route: PrivateRoute,
  //     },
  //     {
  //         path: '/dashboard/projects',
  //         name: 'Project',
  //         component: ProjectDashboard,
  //         route: PrivateRoute,
  //     },
  // ],
};

// const calendarAppRoutes = {
//     path: '/apps/calendar',
//     name: 'Calendar',
//     route: PrivateRoute,
//     roles: ['Admin'],
//     icon: 'uil-calender',
//     component: CalendarApp,
//     header: 'Apps',
// };

// const chatAppRoutes = {
//     path: '/apps/chat',
//     name: 'Chat',
//     route: PrivateRoute,
//     roles: ['Admin'],
//     icon: 'uil-comments-alt',
//     component: ChatApp,
// };

// const ecommerceAppRoutes = {
//     path: '/apps/ecommerce',
//     name: 'eCommerce',
//     route: PrivateRoute,
//     roles: ['Admin'],
//     icon: 'uil-store',
//     children: [
//         {
//             path: '/apps/ecommerce/products',
//             name: 'Products',
//             component: EcommerceProducts,
//             route: PrivateRoute,
//         },
//         {
//             path: '/apps/ecommerce/details',
//             name: 'Product Details',
//             component: ProductDetails,
//             route: PrivateRoute,
//         },
//         {
//             path: '/apps/ecommerce/orders',
//             name: 'Orders',
//             component: Orders,
//             route: PrivateRoute,
//         },
//         {
//             path: '/apps/ecommerce/order/details',
//             name: 'Order Details',
//             component: OrderDetails,
//             route: PrivateRoute,
//         },
//         {
//             path: '/apps/ecommerce/customers',
//             name: 'Customers',
//             component: Customers,
//             route: PrivateRoute,
//         },
//         {
//             path: '/apps/ecommerce/shopping-cart',
//             name: 'Shopping Cart',
//             component: Cart,
//             route: PrivateRoute,
//         },
//         {
//             path: '/apps/ecommerce/checkout',
//             name: 'Checkout',
//             component: Checkout,
//             route: PrivateRoute,
//         },
//         {
//             path: '/apps/ecommerce/sellers',
//             name: 'Sellers',
//             component: Sellers,
//             route: PrivateRoute,
//         },
//     ],
// };

// const emailAppRoutes = {
//     path: '/apps/email',
//     name: 'Email',
//     route: PrivateRoute,
//     roles: ['Admin'],
//     icon: 'uil-envelope',
//     children: [
//         {
//             path: '/apps/email/inbox',
//             name: 'Inbox',
//             component: Inbox,
//             route: PrivateRoute,
//         },
//         {
//             path: '/apps/email/details',
//             name: 'Email Details',
//             component: EmailDetail,
//             route: PrivateRoute,
//         },
//     ],
// };

// const projectAppRoutes = {
//     path: '/apps/projects',
//     name: 'Projects',
//     route: PrivateRoute,
//     roles: ['Admin'],
//     icon: 'uil-briefcase',
//
//     children: [
//         {
//             path: '/apps/projects/list',
//             name: 'List',
//             component: Projects,
//             route: PrivateRoute,
//         },
//         {
//             path: '/apps/projects/:id/details',
//             name: 'Detail',
//             component: ProjectDetails,
//             route: PrivateRoute,
//         },
//         {
//             path: '/apps/projects/gantt',
//             name: 'Gantt',
//             component: ProjectGannt,
//             route: PrivateRoute,
//         },
//         {
//             path: '/apps/projects/new',
//             name: 'Create Project',
//             component: ProjectForm,
//             route: PrivateRoute,
//         },
//     ],
// };

// const socialAppRoutes = {
//     path: '/apps/social',
//     name: 'Social Feed',
//     route: PrivateRoute,
//     roles: ['Admin'],
//     icon: 'uil-rss',
//     component: SocialFeed,
// };

// const taskAppRoutes = {
//     path: '/apps/tasks',
//     name: 'Tasks',
//     route: PrivateRoute,
//     roles: ['Admin'],
//     icon: 'uil-clipboard-alt',
//     children: [
//         {
//             path: '/apps/tasks/list',
//             name: 'Task List',
//             component: TaskList,
//             route: PrivateRoute,
//         },
//         {
//             path: '/apps/tasks/details',
//             name: 'Task List',
//             component: TaskDetails,
//             route: PrivateRoute,
//         },
//         {
//             path: '/apps/tasks/kanban',
//             name: 'Kanban',
//             component: Kanban,
//             route: PrivateRoute,
//         },
//     ],
// };

// const fileAppRoutes = {
//     path: '/apps/file',
//     name: 'File Manager',
//     route: PrivateRoute,
//     roles: ['Admin'],
//     icon: 'uil-folder-plus',
//     component: FileManager,
// };

/************ MINER ************/

const minerProjects = {
  path: "/projekty",
  name: "Projekty",
  route: PrivateRoute,
  children: [
    {
      path: "/projekty/favourites/:userId/:favouriteId",
      name: "Projekty",
      component: Projekty,
      route: PrivateRoute,
      permissions: [permissions.projects.SHOW_PROJECTS.key],
    },
    {
      path: "/projekty/all/:search",
      name: "Projekty",
      component: Projekty,
      route: PrivateRoute,
      permissions: [permissions.projects.SHOW_PROJECTS.key],
    },
    {
      path: "/projekty/all",
      name: "Projekty",
      component: Projekty,
      route: PrivateRoute,
      permissions: [permissions.projects.SHOW_PROJECTS.key],
    },
    {
      path: "/projekty/new",
      name: "Nowy projekt",
      component: AddProject,
      route: PrivateRoute,
      permissions: [permissions.projects.ADD_PROJECT.key],
    },
    {
      path: "/projekty/:hash",
      name: "Project Details",
      component: ProjectDetails,
      route: PrivateRoute,
      permissions: [permissions.projects.SHOW_PROJECT.key],
    },
  ],
};

const minerFleet = {
  path: "/flota",
  name: "Flota",
  route: PrivateRoute,
  children: [
    {
      path: "/flota/new",
      name: "Nowy samochód",
      component: AddCar,
      route: PrivateRoute,
      permissions: [permissions.cars.ADD_CAR.key],
    },
    {
      path: "/flota/:id",
      name: "Samochód",
      component: CarDetails,
      route: PrivateRoute,
      permissions: [permissions.cars.SHOW_CAR.key],
    },
    {
      path: "/flota",
      name: "Flota",
      component: Fleet,
      route: PrivateRoute,
      permissions: [permissions.cars.SHOW_FLEET.key],
    },
  ],
};

const minerComputers = {
  path: "/komputery",
  name: "Komputery",
  route: PrivateRoute,
  children: [
    {
      path: "/komputery/new",
      name: "Nowy komputer",
      component: AddComputer,
      route: PrivateRoute,
      permissions: [permissions.computers.ADD_COMPUTER.key],
    },
    {
      path: "/komputery/:id",
      name: "Komputer",
      component: ComputerDetails,
      route: PrivateRoute,
      permissions: [permissions.computers.SHOW_COMPUTER.key],
    },
    {
      path: "/komputery",
      name: "Komputery",
      component: Computers,
      route: PrivateRoute,
      permissions: [permissions.computers.SHOW_COMPUTERS.key],
    },
  ],
};

const minerVacations = [
  {
    path: "/vacation/calendar",
    name: "Urlopy - Kalendarz",
    route: PrivateRoute,
    permissions: [permissions.vacations.SHOW_VACATION_CALENDAR.key],
    component: VacationCalendar,
  },
  {
    path: "/vacation/employees",
    name: "Urlopy - Pracownicy",
    route: PrivateRoute,
    permissions: [
      permissions.vacations.SHOW_VACATION_EMPLOYEES_LIST_ALL.key,
      permissions.vacations.SHOW_VACATION_EMPLOYEES_LIST_ONLY_OFFICE.key,
    ],
    component: VacationEmployees,
  },
  {
    path: "/vacation/requests",
    name: "Urlopy - Wnioski",
    route: PrivateRoute,
    permissions: [permissions.vacations.SHOW_VACATION_REQUESTS_LIST.key],
    component: VacationRequests,
  },
  {
    path: "/vacation/request/:requestId",
    name: "Szczegóły wniosku",
    route: PrivateRoute,
    permissions: [permissions.vacations.SHOW_VACATION_REQUEST_DETAILS.key],
    component: VacationRequest,
  },
  {
    path: "/vacation/details/:userId/:requestId",
    name: "Szczegóły wniosku",
    route: PrivateRoute,
    permissions: [permissions.vacations.SHOW_VACATION_DETAILS.key],
    component: VacationRequest,
  },
  {
    path: "/vacation/:userId",
    name: "Urlopy",
    route: PrivateRoute,
    permissions: [permissions.vacations.SHOW_VACATION_EMPLOYEE_SUMMARY.key],
    component: Vacation,
  },
  {
    path: "/vacation",
    name: "Urlopy",
    route: PrivateRoute,
    permissions: [permissions.vacations.SHOW_MY_VACATIONS.key],
    component: Vacation,
  },
];

const minerSettings = {
  path: "/ustawienia",
  name: "Ustawienia",
  route: PrivateRoute,
  permissions: [permissions.settings.SHOW_SETTINGS.key],
  component: Settings,
};

const minerAdministration = {
  path: "/administracja",
  name: "Administracja",
  route: PrivateRoute,
  permissions: [permissions.administration.SHOW_ADMINISTRATION_PANEL.key],
  component: Employees,
};

const minerStatuses = {
  path: "/statusy",
  name: "Statusy",
  route: PrivateRoute,
  permissions: [permissions.statuses.SHOW_STATUSES.key],
  component: Statuses,
};

/************ MINER ************/

const appRoutes = [minerSettings, minerAdministration, minerStatuses, minerProjects, minerFleet, minerComputers];

const otherPublicRoutes = [
  {
    path: "/maintenance",
    name: "Maintenance",
    component: Maintenance,
    route: Route,
  },
  {
    path: "/error-404",
    name: "Error - 404",
    component: ErrorPageNotFound,
    route: Route,
  },
  {
    path: "/error-500",
    name: "Error - 500",
    component: ServerError,
    route: Route,
  },
];

// flatten the list of all nested routes
const flattenRoutes = (routes) => {
  let flatRoutes = [];

  routes = routes || [];
  routes.forEach((item) => {
    flatRoutes.push(item);

    if (typeof item.children !== "undefined") {
      flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
    }
  });
  return flatRoutes;
};

// auth
const authRoutes = [
  {
    path: "/users/login",
    name: "Login",
    component: Login,
    route: Route,
  },
  {
    path: "/users/logout",
    name: "Logout",
    component: Logout,
    route: Route,
  },
  {
    path: "/users/register",
    name: "Register",
    component: Register,
    route: PrivateRoute,
  },
];

// All routes
const authProtectedRoutes = [rootRoute, dashboardRoutes, ...appRoutes, ...minerVacations];
const publicRoutes = [...authRoutes, ...otherPublicRoutes];

const authProtectedFlattenRoutes = flattenRoutes([...authProtectedRoutes]);
const publicProtectedFlattenRoutes = flattenRoutes([...publicRoutes]);

export { publicRoutes, authProtectedRoutes, authProtectedFlattenRoutes, publicProtectedFlattenRoutes };
