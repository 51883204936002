// console.log(process.env);

let host = document.location.host;
let react_api;

if (host.includes("localhost")) {
  react_api = "http://localhost:5000/";
} else {
  react_api = "https://" + host;
}

// console.log("react_api -> ", react_api);

const config = {
  API_URL: react_api,
};

export default config;
