import jwtDecode from "jwt-decode";
import axios from "axios";
import Cookies from "js-cookie";

import config from "../../config";

const _axios = axios.create({
  baseURL: config.API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

// intercepting to capture errors
_axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    let message;

    if (error && error.response && error.response.status === 404) {
      // window.location.href = '/not-found';
    } else if (error && error.response && error.response.status === 403) {
      window.location.href = "/dashboard";
    } else {
      switch (error.response.status) {
        case 401:
          message = "Niepoprawny login lub hasło";
          break;
        case 403:
          message = "Nie masz dostępu do tej strony!";
          break;
        case 404:
          message = "Nie można znaleźć podanej ścieżki...";
          break;
        default: {
          message = error.response && error.response.data ? error.response.data["message"] : error.message || error;
        }
      }
      console.log(message);
      return Promise.reject(message);
    }
  }
);

const AUTH_SESSION_KEY = "miner_user";

/**
 * Sets the default authorization
 * @param {*} token
 */
const setAuthorization = (token) => {
  if (token) _axios.defaults.headers.common["Authorization"] = token;
  else delete _axios.defaults.headers.common["Authorization"];
};

// Cookies based
const getUserFromSession = () => {
  const user = Cookies.get(AUTH_SESSION_KEY);
  return user ? (typeof user == "object" ? user : JSON.parse(user)) : null;
};

class APICore {
  /**
   * Fetches data from given url
   */
  get = (url, params) => {
    let response;
    if (params) {
      var queryString = params
        ? Object.keys(params)
            .map((key) => key + "=" + params[key])
            .join("&")
        : "";
      response = _axios.get(`${url}?${queryString}`, params);
    } else {
      response = _axios.get(`${url}`, params);
    }
    return response;
  };

  getFile = (url, params) => {
    let response;
    if (params) {
      var queryString = params
        ? Object.keys(params)
            .map((key) => key + "=" + params[key])
            .join("&")
        : "";
      response = _axios.get(`${url}?${queryString}`, { responseType: "blob" });
    } else {
      response = _axios.get(`${url}`, { responseType: "blob" });
    }
    return response;
  };

  getMultiple = (urls, params) => {
    const reqs = [];
    let queryString = "";
    if (params) {
      queryString = params
        ? Object.keys(params)
            .map((key) => key + "=" + params[key])
            .join("&")
        : "";
    }

    for (const url of urls) {
      reqs.push(axios.get(`${url}?${queryString}`));
    }
    return axios.all(reqs);
  };

  /**
   * post given data to url
   */
  create = (url, data) => {
    return _axios.post(url, data);
  };

  /**
   * Updates patch data
   */
  updatePatch = (url, data) => {
    return _axios.patch(url, data);
  };

  /**
   * Updates data
   */
  update = (url, data) => {
    return _axios.put(url, data);
  };

  /**
   * Deletes data
   */
  delete = (url) => {
    return _axios.delete(url);
  };

  /**
   * post given data to url with file
   */
  createWithFile = (url, data) => {
    const formData = new FormData();
    for (const k in data) {
      formData.append(k, data[k]);
    }

    const config = {
      headers: {
        ..._axios.defaults.headers,
        "content-type": "multipart/form-data",
      },
    };
    return _axios.post(url, formData, config);
  };

  /**
   * post given data to url with file
   */
  updateWithFile = (url, data) => {
    const formData = new FormData();
    for (const k in data) {
      formData.append(k, data[k]);
    }

    const config = {
      headers: {
        ..._axios.defaults.headers,
        "content-type": "multipart/form-data",
      },
    };
    return _axios.patch(url, formData, config);
  };

  isUserAuthenticated = () => {
    const user = getUserFromSession();
    // _axios.get('/users/protected', {headers: {'Authorization': user.token}})
    //     .then(res => {
    //         console.log(res.status);
    //     });
    if (!user || (user && !user.token)) {
      return false;
    }
    const decoded = jwtDecode(user.token);
    const decodedTime = decoded.exp;
    const currentTime = Date.now() / 1000;
    // console.log(decodedTime, currentTime);
    if (decodedTime < currentTime) {
      console.warn("access token expired");
      this.setLoggedInUser(false);
      return false;
    } else {
      return true;
    }
  };

  // Cookies based
  setLoggedInUser = (session) => {
    if (session) Cookies.set(AUTH_SESSION_KEY, JSON.stringify(session));
    else {
      Cookies.remove(AUTH_SESSION_KEY);
    }
  };

  // getRole(token) {
  //     return _axios.get("/users/getRole/", {headers: {'Authorization': token}})
  //         .then(res => {
  //             // console.log(res.data);
  //             return res.data;
  //         });
  // }

  /**
   * Returns the logged-in user
   */
  getLoggedInUser = () => {
    const user = getUserFromSession();
    if (user !== null) return jwtDecode(user.token);
    return user;
  };

  getUserData = () => {
    if (this.isUserAuthenticated()) {
      const user = getUserFromSession();
      const decoded = jwtDecode(user.token);
      const headers = {
        Authorization: user.token,
      };
      const data = {
        id: decoded.sub,
      };
      return _axios.post("/users/getUserInfo", data, { headers: headers });
      // .then(res => {
      //     // console.log(res.data);
      //     return res.data;
      // });
    }
  };

  // Cookies based
  setUserInSession = (modifiedUser) => {
    let userInfo = Cookies.get(AUTH_SESSION_KEY);
    if (userInfo) {
      const { token, user } = JSON.parse(userInfo);
      this.setLoggedInUser({ token, ...user, ...modifiedUser });
    }
  };
}

/*
Check if token available in session
*/
let user = getUserFromSession();
if (user) {
  const { token } = user;
  if (token) {
    setAuthorization(token);
  }
}

export { APICore, setAuthorization };
