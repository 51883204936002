import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";

import { APICore } from "../helpers/api/apiCore";
import { logoutUser } from "../redux/auth/actions";
import { useQuery } from "@tanstack/react-query";
import { getModules } from "../api/settings";
import { getLoggedInUser } from "../api/users";

const api = new APICore();

/**
 * Private Route forces the authorization before the route can be accessed
 * @param {*} param0
 * @returns
 */
const PrivateRoute = ({ component: Component, roles, permissions, name, ...rest }) => {
  useEffect(() => {
    if (name && name !== "Project Details") {
      document.title = "Miner - " + name;
    }
  }, [name]);

  const { data: modules } = useQuery({
    queryKey: ["settings", "enabled-modules"],
    queryFn: () => getModules(),
  });

  const redirectIfModuleIsDisabled = () => {
    let moduleNameToRedirect = null;

    modules &&
      modules.forEach((module) => {
        // redirect to dashboard if user is not allowed to access module
        if (window.location.pathname.includes(module.name) && !module.enabled) {
          moduleNameToRedirect = module.name;
        }
      });

    return moduleNameToRedirect;
  };

  return (
    <Route
      {...rest}
      render={(props) => {
        if (api.isUserAuthenticated() === false) {
          // not logged in so redirect to login page with the return url
          console.log("Token nieważny, redirect");
          logoutUser();
          return <Redirect to={{ pathname: "/users/login", state: { from: props.location } }} />;
        }

        const moduleName = redirectIfModuleIsDisabled();

        if (moduleName) {
          return (
            <Redirect
              to={{
                pathname: "/dashboard",
                state: { reason: `Moduł ${moduleName} jest wyłączony!` },
              }}
            />
          );
        }

        // get logged-in user data from jwt
        const loggedInUser = getLoggedInUser();

        // check user permissions
        let userPermissions = loggedInUser.permissions;
        let revoke = true;
        for (const permission of userPermissions) {
          if (permissions && permissions.indexOf(permission) !== -1) revoke = false;
        }
        if (permissions && revoke === true) {
          // permission not authorised so redirect to home page
          return (
            <Redirect
              to={{
                pathname: "/dashboard",
                state: {
                  reason: "Nie masz uprawnień do wyświetlenia tej strony!",
                },
              }}
            />
          );
        }

        // authorised so return component
        return <Component {...props} />;
      }}
    />
  );
};

export default PrivateRoute;
