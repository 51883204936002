// @flow
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import Cookies from "js-cookie";

import {
  forgotPassword as forgotPasswordApi,
  forgotPasswordConfirm,
  login as loginApi,
  logout as logoutApi,
  signup as signupApi,
} from "../../helpers/";

import { APICore, setAuthorization } from "../../helpers/api/apiCore";
import { authApiResponseSuccess, authApiResponseError } from "./actions";
import { AuthActionTypes } from "./constants";

const api = new APICore();

/**
 * Login the user
 * @param {*} payload - username and password
 */
function* login({ payload: { username, password } }) {
  try {
    const response = yield call(loginApi, { username, password });
    const user = response.data;
    // NOTE - You can change this according to response format from your api
    // Cookies.set("miner_logged_in", true);
    api.setLoggedInUser(user);
    setAuthorization(user["token"]);
    yield put(authApiResponseSuccess(AuthActionTypes.LOGIN_USER, user));
  } catch (error) {
    console.log(error);
    yield put(authApiResponseError(AuthActionTypes.LOGIN_USER, error));
    api.setLoggedInUser(null);
    setAuthorization(null);
  }
}

/**
 * Logout the user
 */
function* logout() {
  try {
    yield call(logoutApi);
    // Cookies.remove("miner_logged_in");
    api.setLoggedInUser(null);
    setAuthorization(null);
    yield put(authApiResponseSuccess(AuthActionTypes.LOGOUT_USER, {}));
  } catch (error) {
    yield put(authApiResponseError(AuthActionTypes.LOGOUT_USER, error));
  }
}

function* signup({ payload: { username, fullName, gender, email, password, office, role } }) {
  try {
    const response = yield call(signupApi, { username, fullName, gender, email, password, office, role });
    const user = response.data;
    // api.setLoggedInUser(user);
    // setAuthorization(user['token']);
    yield put(authApiResponseSuccess(AuthActionTypes.SIGNUP_USER, user));
  } catch (error) {
    yield put(authApiResponseError(AuthActionTypes.SIGNUP_USER, error));
    api.setLoggedInUser(null);
    setAuthorization(null);
  }
}

function* forgotPassword({ payload: { username } }) {
  try {
    const response = yield call(forgotPasswordApi, { username });
    yield put(authApiResponseSuccess(AuthActionTypes.FORGOT_PASSWORD, response.data));
  } catch (error) {
    yield put(authApiResponseError(AuthActionTypes.FORGOT_PASSWORD, error));
  }
}

function* forgotPasswordChange({ payload: { data } }) {
  try {
    const response = yield call(forgotPasswordConfirm, data);
    yield put(authApiResponseSuccess(AuthActionTypes.FORGOT_PASSWORD_CHANGE, response.data));
  } catch (error) {
    yield put(authApiResponseError(AuthActionTypes.FORGOT_PASSWORD_CHANGE, error));
  }
}

export function* watchLoginUser(): any {
  yield takeEvery(AuthActionTypes.LOGIN_USER, login);
}

export function* watchLogout(): any {
  yield takeEvery(AuthActionTypes.LOGOUT_USER, logout);
}

export function* watchSignup(): any {
  yield takeEvery(AuthActionTypes.SIGNUP_USER, signup);
}

export function* watchForgotPassword(): any {
  yield takeEvery(AuthActionTypes.FORGOT_PASSWORD, forgotPassword);
}

export function* watchForgotPasswordChange(): any {
  yield takeEvery(AuthActionTypes.FORGOT_PASSWORD_CHANGE, forgotPasswordChange);
}

function* authSaga(): any {
  yield all([
    fork(watchLoginUser),
    fork(watchLogout),
    fork(watchSignup),
    fork(watchForgotPassword),
    fork(watchForgotPasswordChange),
  ]);
}

export default authSaga;
