import { checkPermission } from "../api/users";
import permissions from "../constants/permissions";

export const hasPermissionToFolder = (folder, fileCategories) => {
  if (!fileCategories) {
    return false;
  }

  const restrictedCategories = fileCategories?.filter((category) => category.isProtected === true);
  const isFolderRestricted = restrictedCategories.some((category) => category.categoryName === folder);

  if (folder === "Faktury kosztowe" && checkPermission(permissions.projects.MANAGE_INVOICES_IN_PROJECT.key)) {
    return true;
  }

  if (isFolderRestricted && checkPermission(permissions.settings.SHOW_RESTRICTED_ATTACHMENTS_FOLDERS.key)) {
    return true;
  }

  return !isFolderRestricted;
};
