import { APICore } from "../helpers/api/apiCore";

const api = new APICore();

export const getUsers = () => {
  return api.get(`/users/get`).then((res) => res.data);
};

export const getUsersPermissions = () => {
  return api.get(`/users/get-permissions`).then((res) => res.data);
};

export const getUserPermissions = (id) => {
  return api.get(`/users/get-permissions/${id}`).then((res) => res.data);
};

export const getUsersForDropdown = () => {
  return api.get(`/users/get-for-dropdown`).then((res) => res.data);
};

export const getUsersGroupedForDropdown = () => {
  return api.get(`/users/get-for-dropdown-grouped`).then((res) => res.data);
};

export const updateUserAdditionalPermissions = (data) => {
  return api.update(`/users/update-additional-permissions`, data).then((res) => res.data);
};

export const getFavourites = () => {
  return api.get(`/users/get_favourites`).then((res) => res.data.favourites);
};

export const getFavouriteCars = () => {
  return api.get(`/users/get_favourite_cars`).then((res) => res.data.favouriteCars);
};

export const toggleFavouriteCar = (carId, carBrand, carModel) => {
  return api
    .update(`/users/toggle_favourite_car`, { id: carId, label: `${carBrand} ${carModel}` })
    .then((res) => res.data);
};

export const addRecentActivity = (activity) => {
  return api.update(`/users/add_recent_activity`, activity).then((res) => res.data);
};

export const getRecentActivity = () => {
  return api.get(`/users/get_recent_activity`).then((res) => res.data.recentActivity);
};

export const updateVacationLimitForYear = (userId, year, limit) => {
  return api.update(`/users/update_vacation_limit_for_year/${userId}`, { year, limit }).then((res) => res.data);
};

export const getVacationsDataForUser = (userId) => {
  return api.get(`/users/get_vacations/${userId}`).then((res) => res.data);
};

export const getLoggedInUser = () => {
  return api.getLoggedInUser();
};

export const checkPermission = (permission) => {
  const permissions = api.getLoggedInUser().permissions;
  return !!permissions.includes(permission);
};

export const checkPermissionForUser = async (userId, permission) => {
  const permissionsObject = await getUserPermissions(userId);
  const permissions = [...permissionsObject.permissions, ...permissionsObject.additionalPermissions];

  return !!permissions.includes(permission);
};
