// @flow
import { APICore } from "./apiCore";

const api = new APICore();

// account
function login(params: any): any {
  const baseUrl = "/users/login";
  return api.create(`${baseUrl}`, params);
}

function logout(): any {
  const baseUrl = "/users/logout";
  return api.create(`${baseUrl}`, {});
}

function signup(params: any): any {
  const baseUrl = "/users/register";
  return api.create(`${baseUrl}`, params);
}

function forgotPassword(params: any): any {
  const baseUrl = "/users/forget-password";
  return api.create(`${baseUrl}`, params);
}

function forgotPasswordConfirm(params: any): any {
  const baseUrl = "/users/password/reset/confirm";
  return api.create(`${baseUrl}`, params);
}

export { login, logout, signup, forgotPassword, forgotPasswordConfirm };
